<template>
  <nav
    class="navbar"
    :class="[
      { 'navbar-expand-lg': expand },
      { [`navbar-${effect}`]: effect },
      { 'navbar-transparent': transparent },
      { [`bg-${type}`]: type },
      { rounded: round }
    ]"
  >
    <div class="container-fluid">
      <slot name="conainer-pre"></slot>
      <slot name="brand">
        <a
          class="navbar-brand"
          href="javascript:void()"
          @click.prevent="onTitleClick"
        >
          {{ title }}
        </a>
      </slot>

      <navbar-toggle-button
        :toggled="toggled"
        :target="contentId"
        @click.native.stop="toggled = !toggled"
      >
      </navbar-toggle-button>

      <slot name="container-after"></slot>

      <div
        class="collapse navbar-collapse"
        :class="{ show: toggled }"
        :id="contentId"
        v-click-outside="closeMenu"
      >
        <div class="navbar-collapse-header">
          <slot name="content-header" :close-menu="closeMenu"></slot>
        </div>
        <slot :close-menu="closeMenu"></slot>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "./NavbarToggleButton";

export default {
  name: "base-nav",
  components: {
    NavbarToggleButton
  },
  props: {
    type: {
      type: String,
      default: "primary",
      description:
        "Navbar type (e.g default, primary, warning, danger, info, success)"
    },
    title: {
      type: String,
      default: "",
      description: "Title of navbar"
    },
    effect: {
      type: String,
      default: "dark",
      description: "Effect of the navbar (light/dark)"
    },
    round: {
      type: Boolean,
      default: false,
      description: "Whether navbar has rounded corners"
    },
    transparent: {
      type: Boolean,
      default: false,
      description: "Whether navbar is transparent"
    },
    expand: {
      type: Boolean,
      default: false,
      description: "Whether navbar should contain 'navbar-expand-lg' class"
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString(),
      description: "Id for menu"
    }
  },
  data() {
    return {
      toggled: false
    };
  },
  methods: {
    onTitleClick(evt) {
      this.$emit("title-click", evt);
    },
    closeMenu() {
      this.toggled = false;
    }
  }
};
</script>
<style></style>
