<template>
  <header class="header-5">
    <base-nav
      ref="nav"
      class="navbar-main bg-white"
      type=""
      effect="white"
      expand
    >
      <img
        @click="$route.path != '/' ? $router.push('/') : ''"
        slot="conainer-pre"
        class="mobile-logo"
        src="@/assets/img/home/logo.png"
        alt="Sherlock IT"
      />
      <div class="col-md-2">
        <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
          <img
            class="page-logo"
            src="@/assets/img/home/logo.png"
            alt="Sherlock IT"
          />
        </router-link>
      </div>
      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a
            @click="
              $route.path != '/' ? $router.push('/') : '';
              closeMenu();
            "
          >
            <img src="@/assets/img/brand/logo.png" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>
      <div class="col-md-6">
        <ul
          class="navbar-nav navbar-nav-hover justify-content-lg-center ml-lg-auto"
        >
          <li @click="closeMenu()" :class="`nav-item ni ${navbar_active('/')}`">
            <router-link class="nav-link" to="/"> Home </router-link>
          </li>

          <base-dropdown
            :class="`nav-item ${navbar_active('/search,/direct-search')}`"
          >
            <router-link
              role="button"
              slot="title"
              class="nav-link"
              to="/search"
              data-toggle="dropdown"
            >
              <span class="nav-link-inner--text">Search </span>
            </router-link>
            <div>
              <span @click="closeMenu()">
                <router-link to="/search" class="dropdown-item">
                  General Search
                </router-link>
              </span>
              <span @click="closeMenu()">
                <router-link to="/direct-search" class="dropdown-item">
                  Direct Search
                </router-link>
              </span>
            </div>
          </base-dropdown>

          <li
            @click="closeMenu()"
            :class="`nav-item ni ${navbar_active('/faq')}`"
          >
            <router-link class="nav-link" to="/faq"> FAQ </router-link>
          </li>

          <li
            @click="closeMenu()"
            :class="`nav-item ni ${navbar_active('/blog')}`"
          >
            <router-link class="nav-link" to="/blog"> Blog </router-link>
          </li>
          <li
            @click="closeMenu()"
            :class="`nav-item ni ${navbar_active('/about-us')}`"
          >
            <router-link class="nav-link" to="/about-us">
              About Us
            </router-link>
          </li>
          <li
            @click="closeMenu()"
            :class="`nav-item ni ${navbar_active('/contact-us')}`"
          >
            <router-link class="nav-link" to="/contact-us">
              Contact Us
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-md-4">
        <ul class="navbar-nav navbar-nav-hover ml-lg-auto flx-end">
          <li
            @click="closeMenu()"
            v-if="!$store.getters.auth"
            :class="`nav-item ni ${navbar_active('/login')}`"
          >
            <router-link class="nav-link" to="/login"> Log In </router-link>
          </li>

          <li
            @click="closeMenu()"
            v-if="!$store.getters.auth"
            :class="`${navbar_active('/signup')}`"
          >
            <router-link class="tryfree " to="/signup">
              Try for free
            </router-link>
          </li>
          <li
            @click="closeMenu()"
            v-if="this.$store.getters.live_data[0] && this.$store.getters.auth"
            :class="`nav-item ni ${navbar_active('/live')}`"
          >
            <router-link class="nav-link text-danger" to="/live">
              Live Data
              <badge type="danger">{{ $store.getters.live_data.length }}</badge>
            </router-link>
          </li>
          <base-dropdown
            v-if="$store.getters.auth"
            :class="`nav-item ${navbar_active('/history,/account')}`"
          >
            <a
              role="button"
              slot="title"
              href="#"
              class="nav-link"
              data-toggle="dropdown"
            >
              <!-- <i class="ni ni-ui-04 d-lg-none"></i> -->
              <span class="nav-link-inner--text"
                >My Account <i class="fa fa-chevron-down"></i>
              </span>
            </a>
            <div>
              <span @click="closeMenu()">
                <router-link to="/account" class="dropdown-item">
                  <i class="fa fa-user text-primary"></i>
                  Profile
                </router-link>
              </span>
              <span @click="closeMenu()">
                <router-link to="/history" class="dropdown-item">
                  <i class="fa fa-history text-warning"></i>
                  History
                </router-link>
              </span>
              <span @click="logout()" class="dropdown-item"
                ><i class="fa fa-sign-out"></i> Logout</span
              >
            </div>
          </base-dropdown>
        </ul>
      </div>
    </base-nav>
  </header>
</template>
<script>
import BaseDropdown from "@/components/BaseDropdown";
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  },
  data: () => ({
    navs: [
      {
        text: "Home",
        to: "/",
        pos: "before"
      },
      // { text: "About Us", to: "/about-us" },
      // { text: "Search", to: "/search" },
      // { text: "Contact Us", to: "/contact-us" },
      {
        text: "FAQ",
        to: "/faq",
        pos: "after"
      },
      {
        text: "Blog",
        to: "/blog",
        pos: "after"
      }
    ]
  }),
  methods: {
    navbar_active(path) {
      var res = "";
      let paths = path.split(",");
      paths.forEach(p => {
        if (this.$route.path == p) {
          return (res = "na");
        }
      });
      return res;
    },
    closeMenu() {
      if (this.$refs.nav.$data.toggled) {
        this.$refs.nav.$data.toggled = false;
      }
    },
    logout() {
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      window.location.reload();
    }
  },
  props: {
    navbarType: String
  }
};
</script>
<style></style>
